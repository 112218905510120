import React from 'react';
import Typewriter from 'typewriter-effect';
import VideoMP4 from '../../videos/video9-1.mp4';
import VideoWebM from '../../videos/video9.webm';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP } from './HeroElements';
import { useTranslation } from "react-i18next";

const HeroSection = () => {
    const { t } = useTranslation();    
    
    return (
        <HeroContainer>
            <HeroBg>
                {/* <VideoBg autoPlay="autoplay" playsInLine="playsinline" loop="true" muted="true">*/}
                <VideoBg autoPlay playsInline loop muted preload="auto">
                <source type="video/mp4" src={VideoMP4} />
                <source type="video/webm" src={VideoWebM} />
                Your browser does not support the video tag.
                </VideoBg>                 
            </HeroBg>
            <HeroContent>
            <HeroH1><Typewriter
          options={{
          strings: ['Opencart','Online stores','E-commerce solutions','API & data sync'],          
          autoStart: true,          
          loop: 1,          
          pauseFor: 7000,                                      
  }}
/></HeroH1>
            <HeroP>
   {t("E-commerce solutions | API & data sync | OpenCart, PHP, Node.js, React/Next.js | Building scalable stores & custom integrations")}</HeroP>
        </HeroContent>            
        </HeroContainer>
    );
};

export default HeroSection;