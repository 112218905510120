export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'About Us',
    headLine: 'Online stores',
    description: 'Development online stores on OpenCart, integration of modules, parsing, import-export contents, data synchronization with trading platforms and services, payment systems, delivery services, etc. We provide full cycle of services for creating and maintaining online shops: from design to technical support.',
    buttonLabel: 'See more',
    buttonLabel2: 'Examples',    
    imgStart: false,
    img: require('../../images/eshop3.webp'),        
    // exLink: '/about-us',
    exLink: '/',                
    alt: 'eshop',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjTwo = {
    id: 'devmodules',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Opencart',
    headLine: 'Dev modules',
    description: 'Development data synchronization modules about products and site categories with DBMS. Loaders products from files in XLS, CSV, XML format to the online stores database. Data loaders using the suppliers API to the site database. Automation of the product data synchronization process.',
    buttonLabel: 'See more',
    buttonLabel2: 'Modules',    
    imgStart: true,    
    img: require('../../images/dev5.webp'),
    // exLink: '/dev',
    exLink: '/',     
    alt: 'devmodules',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjThree = {
    id: 'webdev',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Node.js',
    headLine: 'Web dev',
    description: 'Developing high-quality websites and online stores using cutting-edge technologies such as Node.js, React/Next.js and MERN stack. Whether you are looking to build a simple website or a complex online store, we are committed to delivering exceptional results that exceed your expectations.',
    buttonLabel: 'See more',
    buttonLabel2: 'Templates',    
    imgStart: false,    
    img: require('../../images/webd3.webp'),        
    // exLink: '/webdev',
    exLink: '/',     
    alt: 'webdesign',
    dark: true,
    primary: true,
    darkText: false
}

// export const homeObjFour = {
//     id: 'smm',
//     lightBg: true,
//     lightText: false,
//     lightTextDesc: false,
//     topLine: 'Advertising',
//     headLine: 'SMM',
//     description: 'Data synchronization and work with various services Facebook, Google, CRM systems',
//     buttonLabel: 'See more',
//     buttonLabel2: 'SMM',
//     imgStart: true,    
//     img: require('../../images/smm.png'),    
//     alt: 'smm',
//     dark: false,
//     primary: false,
//     darkText: true
// }

